<template>
  <div ref="wrapper" class="relative">
    <div ref="content" class="top-0 h-full content lazyload" :style="`background-image: url(${page.image})`">
      <div
        class="pt-40 pb-10"
        :style="`background-image: linear-gradient(rgba(11, 53, 95, 0.2), rgba(11, 53, 95, 0.2))`"
      >
        <div class="container flex flex-col sm:justify-center items-center h-full w-full text-white gap-10">
          <transition name="fade-slide">
            <div v-show="loaded" class="w-11/12 lg:w-7/12 xl:w-1/2 xxl:w-40p">
              <lottie :options="$route.path === '/en' ? engOptions : defaultOptions" @animCreated="handleAnimation"/>
            </div>
          </transition>
          <h1 class="hidden">
            We nemen je graag mee op reis door de golfgeschiedenis…
          </h1>
          <div class="px-10 flex flex-col-reverse lg:flex-row space-between items-center w-full text-white gap-20">
            <div class="flex flex-col h-full lg:w-1/2 justify-center">
              <div v-html="page.tekst"/>
            </div>
            <div class="flex flex-col h-full w-3/4 lg:w-1/2 justify-center">
              <div class="p-5 bg-secondary bg-opacity-75">
                <h2 class="text-4xl">Ga snel naar:</h2>
                <div class="flex flex-wrap">
                  <div v-for="link in homeMenu" :key="link.title" class="w-1/2 pb-3">
                    <nuxt-link
                      :to="link.url"
                      :aria-label="link.title"
                      :class="{'text-primary border-b-2 border-primary bold-link': $route.path === link.url}"
                      class="text-2xl hover:text-primary bold-link-hover"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'arrow-circle-right']"
                        size="lg"
                        class="ml-1 md:ml-3"
                        aria-hidden="true"
                      />
                      {{ link.text }}
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/plugins/mixins/page';
import * as animationDataEng from 'assets/lottie/gg-text-eng.json';
import * as animationData from 'assets/lottie/gg-text.json';
import {mapState} from 'vuex';
import Lottie from 'vue-lottie/src/lottie.vue';

export default {
  components: {
    Lottie,
  },
  mixins: [page],
  data: () => ({
    loaded: false,
    defaultOptions: {animationData: animationData.default, renderer: 'svg'},
    engOptions: {animationData: animationDataEng.default, renderer: 'svg'},
    animationSpeed: 1,
  }),
  computed: {
    ...mapState({homeMenu: state => state.homeMenu}),
  },
  mounted() {
    // Todo: Experimental: trying to bind animations to lazyloaded events so content is rendered after images
    const lazy = () => {
      document.addEventListener('lazyloaded', () => {
        this.loaded = true;
        this.$emit('loaded');
      });
    };

    lazy();
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s 1s;
  transition-timing-function: cubic-bezier(.57, .21, .69, 1.25);
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity .6s, transform 1s;
  transition-timing-function: cubic-bezier(.57, .21, .69, 1.25);
  transition-delay: .5;
}

.fade-slide-enter, .fade-slide-leave-to {
  opacity: 0;
  transform: translateY(5vh);
}

.overlay {
  background-image: linear-gradient(rgba(11, 53, 95, 0.4), rgba(11, 53, 95, 0.4));
}

@media (min-width: theme('screens.md')) {
  .content {
    &::before {
      content: '';
      width: 12rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
      z-index: 1;
      opacity: 0;
      transition: opacity 2s .5s;
    }

    &.loaded::before {
      opacity: 1;
    }
  }
}
</style>
